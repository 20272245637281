import { Flex } from '@mantine/core';

import HomeSkeletonLayout from './components/HomeSkeleton';
import HomeContest from './components/HomeContest';
import { useHomePromoContests } from './hooks/useHomePromoContests';

function HomeContests({ order }: { order?: number }) {
  const { data, isShowContestThumbnailEnabled } = useHomePromoContests();
  const { data: contests, isError, isLoading: isFeatureContestLoading } = data;
  const hasContests = contests?.length > 0;

  if (isFeatureContestLoading) {
    return <HomeSkeletonLayout isLoading />;
  }

  if (!hasContests || isError) {
    return null;
  }

  return (
    <Flex gap={40} direction="column" style={{ order }}>
      {contests.map((contestsRow, index) => (
        <HomeContest
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}`}
          {...contestsRow}
          isShowContestThumbnailEnabled={isShowContestThumbnailEnabled}
        />
      ))}
    </Flex>
  );
}

export default HomeContests;
