import { Box, Flex } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { FEATURE } from '~/common/enums/feature.enum';
import { Routes } from '~/domains/common/constants/routes';
import ContestCard from '~/domains/contest/domains/common/components/ContestCard/ContestCard';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import HomeCarousel from './components/HomeCarousel';
import { useHomeFeaturedContests } from './hooks/useHomeFeaturedContests';
import HomeSkeletonLayout from './components/HomeSkeleton';
import { HomeSectionTitle, HomeViewAllLink } from './components/sectionComponents';

function HomeFeaturedContests({ order }: { order?: number }) {
  const { t } = useTranslation('home');

  const isCountdownPillEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CONTEST_COUNTDOWN_TIMER);

  const { data, isShowContestThumbnailEnabled } = useHomeFeaturedContests();
  const { data: contests, isError, isLoading: isFeatureContestLoading, isInitialLoading } = data;
  const featuredContests = contests?.data;
  const hasFeaturedContests = featuredContests?.length > 0;

  if (isFeatureContestLoading && isInitialLoading) {
    return <HomeSkeletonLayout isLoading />;
  }

  if (!hasFeaturedContests || isError) {
    return null;
  }

  return (
    <Flex direction="column" style={{ order }}>
      <Flex justify="space-between" w="100%" align="baseline">
        <HomeSectionTitle>{t('featured.title')}</HomeSectionTitle>
        <HomeViewAllLink href={Routes.contestLobby()} />
      </Flex>
      <Box p={0} mt={0} component="ul">
        <HomeCarousel>
          {featuredContests?.map((featuredContest, index) => (
            <HomeCarousel.Slide key={featuredContest.id}>
              <ContestCard
                contest={featuredContest}
                isContestThumbnailShown={isShowContestThumbnailEnabled}
                isNotAllowedInLocation={featuredContest.is_allowed_in_location === false}
                isBadgeIconSectionEnabled={false}
                isCountdownPillEnabled={isCountdownPillEnabled}
                promoSource="home_featured_contests"
                promoIndex={index + 1}
              />
            </HomeCarousel.Slide>
          ))}
        </HomeCarousel>
      </Box>
    </Flex>
  );
}

export default HomeFeaturedContests;
