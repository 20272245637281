import { useContext } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { UserContext } from '~/components/providers/UserProvider';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

type HomeOrder = {
  featuredContests?: number;
  quickpicks?: number;
  myEntries?: number;
  popularChannels?: number;
  followers?: number;
  rafBanner?: number;
  contests?: number;
};

export const useHome = () => {
  const { user } = useContext(UserContext);

  const isCMSCarouselEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CMS_CAROUSEL);
  const isQPpromoEnabled = useIsFeatureEnabled(FEATURE.ENABLE_HOME_QP);
  const { isReady, treatments } = useTreatments([FEATURE.HOME_SECTIONS_ORDER]);
  const { config } = treatments[FEATURE.HOME_SECTIONS_ORDER];
  const homeOrder = (isReady && (config as HomeOrder)) || {};

  return {
    isCMSCarouselEnabled,
    isQPpromoEnabled,
    isSignedIn: !!user,
    homeOrder,
  };
};
