import { Box, Flex, Skeleton, Stack } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';
import ContestCard from '~/domains/contest/domains/common/components/ContestCard/ContestCard';

import { useContests } from '../../contest/hooks/useContests';

import HomeCarousel from './HomeCarousel';
import { HomeSectionTitle } from './sectionComponents';

export function PlayWithPeopleYouFollow({ order }: { order?: number }) {
  const { t } = useTranslation('home');

  const isEnabled = useIsFeatureEnabled(FEATURE.ENABLE_PLAY_WITH_PEOPLE_YOU_FOLLOW);

  const { data, isLoading, isInitialLoading } = useContests(
    {
      limit: 20,
      offset: 0,
      filter: { followedChannels: true },
      includeFull: false,
    },
    { enabled: isEnabled }
  );
  const contests = data?.data;

  if (!isEnabled) return null;

  if (isLoading && isInitialLoading)
    return (
      <Stack spacing="lg" style={{ order }}>
        <Skeleton h={28} w={230} />
        <Skeleton mih={170} />
      </Stack>
    );

  if (contests?.length === 0) return null;

  return (
    <Stack spacing={0} style={{ order }}>
      <Flex justify="space-between" w="100%" align="baseline">
        <HomeSectionTitle>{t('playWithPeopleYouFollow.title')}</HomeSectionTitle>
      </Flex>
      <Box
        p={0}
        component="ul"
        mt={0}
        w="100%"
        sx={{
          '& .mantine-Carousel-controls': {
            right: 0, // there is no view all link, thus we need to move the controls to the right
          },
        }}
      >
        <HomeCarousel>
          {contests?.map((contest) => (
            <HomeCarousel.Slide key={contest.id}>
              <ContestCard
                contest={contest}
                isContestThumbnailShown
                isBadgeIconSectionEnabled={false}
                isBannerEnabled
                isCountdownPillEnabled
                isPlayWithPeopleYouFollowEnabled
              />
            </HomeCarousel.Slide>
          ))}
        </HomeCarousel>
      </Box>
    </Stack>
  );
}
