import type { UserContest } from '@betterpool/api-types/contests-service';

import type { MyUserContest } from '../types/userContest';


const parseMyContests = (contests: UserContest[]): MyUserContest[] =>
  // @ts-expect-error TODO([EDGE-14282]): fix this by updating @betterpool/api-types
  contests.map(
    (contest) =>
      ({
        id: contest.contest.id,
        // @ts-expect-error TODO([EDGE-14282]): fix this by updating @betterpool/api-types
        commissioner: contest.commissioner,
        league: contest.league,
        name: contest.contest.name,
        start_date: contest.contest.startDate ? new Date(contest.contest.startDate) : null,
        lock_date: contest.contest.lockDate ? new Date(contest.contest.lockDate) : null,
        pick_lock_date: contest.slate.pickLockDate ? new Date(contest.slate.pickLockDate) : null,
        settled_date: contest.contest.settledDate ? new Date(contest.contest.settledDate) : null,
        entries: {
          filled: contest.entries?.filled,
          max: contest.entries?.max,
          user: contest.entries?.user,
          max_per_user: contest.entries?.maxPerUser,
          is_unlimited_entries: contest.entries?.isUnlimitedEntries,
        },
        entry_fee: contest.contest.entryFee,
        prize_pool: contest.contest.prizePool,
        contest_type: contest.contest.contestType,
        created_by_admin: contest.contest.createdByAdmin,
        thumbnail_url: contest.contest.thumbnailUrl,
        resizing_mode: contest.contest.resizingMode,
        slate: contest.slate,
        status: contest.contest.status,
        survivor: contest.survivor,
        missingPicksEntryIds: contest.picks.missingPicksEntryIds,
        injuredPicksEntryIds: contest.picks.injuredPicksEntryIds,
        leaderboard: contest.leaderboard,
        tags: contest.contest.tags,
        isPicksheetAvailable: contest.picks.isPicksheetAvailable,
        isEntertainmentOnly: contest.contest.isEntertainmentOnly,
      }) satisfies MyUserContest
  );

export default parseMyContests;
