import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatment } from '~/domains/common/hooks/useTreatment';

import HomeSkeletonLayout from './HomeSkeleton';
import MyEntries from './MyEntries';
import MyEntriesLinks from './MyEntriesLinks/MyEntriesLinks';

function HomeMyEntriesSection({ order }: { order?: number }) {
  const { isReady, treatment } = useTreatment(FEATURE.ENABLE_ENTRIES_PAGE);
  const isEnabled = treatment?.treatment === 'on';

  if (!isReady) {
    return (
      <div style={{ order }}>
        <HomeSkeletonLayout isLoading />
      </div>
    );
  }

  if (!isEnabled) {
    return (
      <div style={{ order }}>
        <MyEntriesLinks />
      </div>
    );
  }

  return (
    <div style={{ order }}>
      <MyEntries />
    </div>
  );
}

export default HomeMyEntriesSection;
