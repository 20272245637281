import { Box } from '@mantine/core';

import { RAFBanner } from '~/domains/user/domains/refer-a-friend/components/RAFBanner';
import { HomePopularChannels } from '~/domains/channel/components/HomePopularChannels/HomePopularChannels';
import { BoardProvider } from '~/domains/contest/domains/quickPick/context/BoardContext/BoardContext';

import { useHome } from './hooks/useHome';
import useHomeStyles from './Home.styles';
import HomeFeaturedContests from './Featured';
import HomeContests from './Contests';
import HomeHeader from './Header';
import HomeFooter from './Footer';
import HomeMyEntriesSection from './components/HomeMyEntriesSection';
import { PlayWithPeopleYouFollow } from './components/PlayWithPeopleYouFollow';
import HomeQuickpicks from './components/HomeQuickpicks';

function Home() {
  const { classes, theme } = useHomeStyles();

  const { isCMSCarouselEnabled, isQPpromoEnabled, isSignedIn, homeOrder } = useHome();

  return (
    <Box maw={theme.other.layout.maxWidth} m="0 auto">
      <Box component="section" className={classes.carousel}>
        <HomeHeader isCMSCarouselEnabled={isCMSCarouselEnabled} />

        <HomeFeaturedContests order={homeOrder.featuredContests} />
        {isQPpromoEnabled && (
          <BoardProvider>
            <HomeQuickpicks order={homeOrder.quickpicks} />
          </BoardProvider>
        )}
        {isSignedIn ? (
          <>
            <HomeMyEntriesSection order={homeOrder.myEntries} />
            <HomePopularChannels order={homeOrder.popularChannels} />
            <PlayWithPeopleYouFollow order={homeOrder.followers} />
            <RAFBanner order={homeOrder.rafBanner} />
          </>
        ) : (
          <HomePopularChannels order={homeOrder.popularChannels} />
        )}
        <HomeContests order={homeOrder.contests} />

        <HomeFooter />
      </Box>
    </Box>
  );
}

export default Home;
