import { Box, Flex, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { HomeSectionTitle } from '~/domains/home/components/sectionComponents';
import HomeCarousel from '~/domains/home/components/HomeCarousel';
import HomeSkeletonLayout from '~/domains/home/components/HomeSkeleton';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';

import { ConnectedPopularChannel } from './ConnectedPopularChannel';
import { usePopularChannels } from './usePopularChannels';

export function HomePopularChannels({ order }: { order?: number }) {
  const theme = useMantineTheme();
  const { t } = useTranslation('channel');

  const isEnabled = useIsFeatureEnabled(FEATURE.ENABLE_POPULAR_CHANNELS_ON_HOME);

  const { isLoading, isError, data } = usePopularChannels({ enabled: isEnabled });

  if (isLoading) {
    return (
      <div style={{ order }}>
        <HomeSkeletonLayout isLoading />
      </div>
    );
  }

  if (isError || !data || !isEnabled) {
    return null;
  }

  return (
    <Flex direction="column" style={{ order }}>
      <Flex justify="space-between" w="100%" align="baseline">
        <Flex align="baseline" gap={theme.other.spacing._8}>
          <HomeSectionTitle>{t('popularChannel.title')}</HomeSectionTitle>
        </Flex>
      </Flex>
      <Box
        p={0}
        component="ul"
        mt={0}
        w="100%"
        sx={{
          '.mantine-Carousel-controls': {
            right: 0,
          },
        }}
      >
        <HomeCarousel slideSize={283}>
          {data?.data?.map((channel) => (
            <HomeCarousel.Slide key={channel.id}>
              <ConnectedPopularChannel key={channel.id} channel={channel} />
            </HomeCarousel.Slide>
          ))}
        </HomeCarousel>
      </Box>
    </Flex>
  );
}
